<template>
    <div>
        <layout-full-width :title="$t('inactive-groups')">
            <div class="row">
                <div class="col-2">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <router-link class="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" to="/security/groups" exact active-class="active">
                            {{ $t('active-groups') }}
                        </router-link>
                        <router-link class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" to="/security/groups/inactive-groups" exact active-class="active">
                            {{ $t('inactive-groups') }}
                        </router-link>
                    </div>
                </div>
            </div>

        </layout-full-width>
    </div>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageInactiveGroups",
    components: { LayoutFullWidth }
}
</script>